<template>
<div>

	<!-- /header -->
	<section class="wrapper bg-soft-primary"
					 v-if="xml != undefined">
		<div class="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
			<div class="row">
				<div class="col-md-10 col-xl-8 mx-auto">
					<div class="post-header">
						<div class="post-category text-line">
							<a class="hover"
								 rel="category">{{accroche}}</a>
						</div>
						<!-- /.post-category -->
						<h1 class="display-3 mb-4">{{title}}</h1>

						<ul class="post-meta mb-5">
							<li class="post-date"><i class="uil uil-calendar-alt"></i><span>{{date}}</span></li>
							<li class="post-author"><a href="#"><i class="uil uil-user"></i><span>{{auteur}}</span></a></li>
							<li v-if="comments !=''"
									class="post-comments"><a href="#"><i class="uil uil-comment"></i><span> {{comments}}</span></a></li>
							<li v-if="like !=''"
									class="post-likes"><a href="#"><i class="uil uil-heart-alt"></i><span> {{like}}</span></a></li>
						</ul>
						<!-- /.post-meta -->

						<ul class="list-unstyled tag-list mb-0">
							<li v-for="(item,ib) in category"
									:key="'b'+ib"><a :href="'#'+item.cat_id[0]"
									 class="btn btn-soft-ash btn-sm   mb-0">{{item.libelle[0] }} </a></li>

						</ul>


					</div>
					<!-- /.post-header -->
				</div>
				<!-- /column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>
	<!-- /section -->
	<section class="wrapper bg-light">
		<div class="container pb-14 pb-md-16">
			<div class="row">
				<div class="col-lg-10 mx-auto">
					<div class="blog single mt-n17">
						<div class="card">
							<figure class="card-img-top"><img :src="img"
										 alt="" /></figure>

							<div class="card-body">
								<div class="classic-view">
									<article class="post">
										<div class="post-content mb-5">
											<div class=" "
													 v-html="replaceAll('\n','<br/>',contents)" />
											<template>
												<div v-for="(item,ic) in category"
														 :key="'c'+ic"
														 :id="item.cat_id[0]">
													<blockquote class="fs-lg my-8">
														<p> {{item.libelle[0] }}</p>
													</blockquote>
													<!--begin::Bloc-->
													<div class=" "
															 v-for="(risk,iq) in item.blog"
															 :key="'q'+iq">
														<!--begin::Text-->
														<h4 class="text-green">{{risk.titre[0]}}</h4>
														<p v-html="replaceAll('\n','<br/>',risk.texte[0].trim())"> </p>
														<!--end::Text-->
													</div>
													<!--end::Block-->
												</div>
											</template>
										</div>
										<!-- /.post-content -->
										<div class="post-footer d-md-flex flex-md-row justify-content-md-between align-items-center mt-8">
											<div>
												<ul class="list-unstyled tag-list mb-0">
													<li v-for="(item,ib) in category"
															:key="'b'+ib"><a :href="'#'+item.cat_id[0]"
															 class="btn btn-soft-ash btn-sm rounded-pill mb-0">{{item.libelle[0] }} </a></li>
												</ul>
											</div>
											<div class="mb-0 mb-md-2">
												<div class="dropdown share-dropdown btn-group">
													<button class="btn btn-sm btn-red rounded-pill btn-icon btn-icon-start dropdown-toggle mb-0 me-0"
																	data-bs-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false">
														<i class="uil uil-share-alt"></i> Partager </button>
													<div class="dropdown-menu">
														<a class="dropdown-item"
															 :href="'https://twitter.com/share?url='+urlpartage">
															<i class="uil uil-twitter"></i>Twitter</a>
														<a class="dropdown-item"
															 :href="'https://www.facebook.com/sharer/sharer.php?u=file:'+urlpartage"><i class="uil uil-facebook-f"></i>Facebook</a>
														<a class="dropdown-item"
															 :href="'https://www.linkedin.com/sharing/share-offsite/?url='+urlpartage"><i class="uil uil-linkedin"></i>Linkedin</a>
														<a class="dropdown-item"
															 :href="'http://www.reddit.com/submit?url='+urlpartage"><i class="uil uil-reddit-alien-alt"></i>Reddit</a>
														<a class="dropdown-item"
															 :href="'mailto:?subject=&body='+urlpartage"><i class="uil uil-envelope-alt"></i>email</a>
														<a class="dropdown-item"
															 :href="'https://telegram.me/share/url?url='+urlpartage"><i class="uil uil-telegram"></i>Telegram</a>
														<a class="dropdown-item"
															 :href="'whatsapp://send?text='+urlpartage"><i class="uil uil-whatsapp"></i>Whatsapp</a>
													</div>
													<!--/.dropdown-menu -->
												</div>
												<!--/.share-dropdown -->
											</div>
										</div>
										<!-- /.post-footer -->
									</article>
									<!-- /.post -->
								</div>
								<!-- /.classic-view -->
								<hr />
								<div class="author-info d-md-flex align-items-center mb-3">
									<div class="d-flex align-items-center">
										<figure class="user-avatar"><img class="rounded-circle"
													 alt=""
													 :src='img_auteur' /></figure>
										<div>
											<h6><a href="#"
													 class="link-dark">{{auteur}}</a></h6>
											<span class="post-meta fs-15">{{fonction}} </span>
										</div>
									</div>
									<div class="mt-3 mt-md-0 ms-auto"
											 style="display:none;">
										<a href="#"
											 class="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-start mb-0"><i class="uil uil-file-alt"></i> All Posts</a>
									</div>
								</div>
								<!-- /.author-info -->
								<p>{{authorinfo}}</p>
								<nav class="nav social"
										 style="display:none;">
									<a href="#"><i class="uil uil-twitter"></i></a>
									<a href="#"><i class="uil uil-facebook-f"></i></a>
									<a href="#"><i class="uil uil-dribbble"></i></a>
									<a href="#"><i class="uil uil-instagram"></i></a>
									<a href="#"><i class="uil uil-youtube"></i></a>
								</nav>
								<!-- /.social -->



								<!-- /.comment-form -->
							</div>
							<!-- /.card-body -->
						</div>
						<!-- /.card -->
					</div>
					<!-- /.blog -->
				</div>
				<!-- /column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>
	<!-- /section -->


</div>
</template>


<script>
export default {
	name: 'post1',
	components: {},
	props: {
		accroche: String,
		date: String,
		auteur: String,
		comments: String,
		like: String,
		texte: String,
		xml: String,
		fonction: String,
		avatar: String,
		authorinfo: String,
	},
	data: () => ({
		urlpartage: '',
		title: "",
		img: "",
		subtitle: "",
		img_auteur: "",
		contents: "",
		category: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		// console.log(this.xml);
		//	this.changexml();
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		replaceAll: function replaceAll(recherche, remplacement, chaineAModifier) {
			return chaineAModifier.split(recherche).join(remplacement);
		},
		changexml() {
			this.img_auteur = "https://media.berlcoin.fr/avatars/" + this.avatar + ".jpg";
			this.urlpartage = window.document.location.href;
			var xml2js = require('xml2js');
			var extractedData = "";
			var parser = new xml2js.Parser();
			parser.parseString(this.xml, function (err, result) {
				extractedData = result['blogs'];
			});
			this.title = extractedData['titre'][0].trim();
			this.subtitle = extractedData['soustitre'][0].trim();
			this.img = extractedData['illustration'][0].trim();
			this.contents = extractedData['texte'][0].trim();
			this.category = extractedData['category'];

		}
	},
	watch: {
		xml() {
			this.changexml();
		}
	}
}
</script>

<style scoped>
</style>
